import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { HomeTaxVerse, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'insights';

export default function() {
  const { contentfulHomePage } = useStaticQuery(graphql`
    {
      contentfulHomePage(slug: { eq: "home" }) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
        heroCtaLink {
          slug
          internal {
            type
          }
        }
        heroCtaText
        heroDescription {
          json
        }
        heroHeadline
        heroImage {
          ...ImageSharpLarge
        }
        featureHeadline
        features {
          headline
          blurb {
            json
          }
          icon {
            ...ImageSharpOriginal
          }
        }
        timelineHeadline
        timelineBlurb {
          json
        }
        timeline {
          menuName
          headline
          blurb {
            json
          }
          image {
            ...ImageSharpMedium
          }
        }
        navigators {
          ... on ContentfulNavigator {
            headline
            blurb {
              json
            }
            ctaText
            ctaLink {
              ...NavigatorCtaLink
            }
            image {
              ...ImageSharpMedium
            }
          }
          ... on ContentfulVideo {
            headline
            videoUrl
            blurb {
              json
            }
          }
        }
        featuresCta {
          headline
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
        closingNavigator {
          blurb {
            json
          }
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
          headline
          image {
            ...ImageSharpOriginal
          }
        }
        footerCta {
          headline
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulHomePage.metaTitle,
      description: contentfulHomePage.metaDescription,
      image:
        contentfulHomePage.image && contentfulHomePage.image.localFile.childImageSharp.fluid.src,
    },
    hero: {
      headline: contentfulHomePage.heroHeadline,
      body: contentfulHomePage.heroDescription,
      buttonText: contentfulHomePage.heroCtaText,
      buttonUrl:
        contentfulHomePage.heroCtaLink &&
        linkResolver({
          type: contentfulHomePage.heroCtaLink.internal.type,
          slug: contentfulHomePage.heroCtaLink.slug,
          postPrefix,
        }),
      image: contentfulHomePage.heroImage,
    },
    timeline: {
      headline: contentfulHomePage.timelineHeadline,
      body: contentfulHomePage.timelineBlurb,
      cards:
        contentfulHomePage.timeline &&
        contentfulHomePage.timeline.map((card) => ({
          headline: card.headline,
          body: card.blurb,
          image: card.image,
          buttonText: card.menuName,
        })),
    },
    highlights: {
      cards: contentfulHomePage.navigators.map((navigator) => ({
        headline: navigator.headline,
        body: navigator.blurb,
        buttonText: navigator.ctaText,
        buttonUrl:
          navigator.ctaLink &&
          linkResolver({
            type: navigator.ctaLink[0].internal.type,
            slug: navigator.ctaLink[0].redirectUrl || navigator.ctaLink[0].slug,
            postPrefix,
          }),
        image: navigator.image,
        video: navigator.videoUrl,
        primary: true,
      })),
      background: 'dark-2',
    },
    threeUp: {
      headline: contentfulHomePage.featureHeadline,
      cards: contentfulHomePage.features.map((feature) => ({
        headline: feature.headline,
        body: feature.blurb,
        image: feature.icon && feature.icon.localFile.publicURL,
      })),
      background: 'light-6',
    },
    threeUpCta: contentfulHomePage.featuresCta && {
      headline: contentfulHomePage.featuresCta.headline,
      buttonText: contentfulHomePage.featuresCta.ctaText,
      buttonType:
        contentfulHomePage.featuresCta.ctaLink &&
        contentfulHomePage.featuresCta.ctaLink[0].internal.type,
      buttonUrl:
        contentfulHomePage.featuresCta.ctaLink &&
        linkResolver({
          type: contentfulHomePage.featuresCta.ctaLink[0].internal.type,
          slug:
            contentfulHomePage.featuresCta.ctaLink[0].redirectUrl ||
            contentfulHomePage.featuresCta.ctaLink[0].slug,
          postPrefix,
        }),
      background: 'light-6',
      buttonBackground: 'white',
    },
    cta: {
      headline: contentfulHomePage.closingNavigator.headline,
      body: contentfulHomePage.closingNavigator.blurb,
      buttonText: contentfulHomePage.closingNavigator.ctaText,
      buttonUrl: '/features/#compliance',
      image:
        contentfulHomePage.closingNavigator.image &&
        contentfulHomePage.closingNavigator.image.localFile.publicURL,
      imageTop: true,
    },
    footerCta: {
      headline: contentfulHomePage.footerCta.headline,
      buttonText: contentfulHomePage.footerCta.ctaText,
      buttonUrl:
        contentfulHomePage.featuresCta.ctaLink &&
        linkResolver({
          type: contentfulHomePage.footerCta.ctaLink[0].internal.type,
          slug:
            contentfulHomePage.footerCta.ctaLink[0].redirectUrl ||
            contentfulHomePage.footerCta.ctaLink[0].slug,
          postPrefix,
        }),
      background: 'accent-1',
      primary: true,
      noRule: true,
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <HomeTaxVerse doc={doc} Link={Link} />
    </Layout>
  );
}
